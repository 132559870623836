import { Image, Button, Row, Col, Container } from 'react-bootstrap';
import HomeImages from './HomeImages';


function Content() {
    return (
      <>
       
        <section >
          <Container className='py-5'>
            <Row className='text-center'>
              {/* <Col xs={12} md={6} className='no_mob mob_only'>
                <Image src='images/projects/Project_3/project.jpg' fluid ></Image>
              </Col> */}

              <Col xs={12} md={12}>
                <h2 className='intro_text'>Welcome to Woodstock Kitchens, where craftsmanship meets innovation.</h2>
              </Col>
            </Row>
          </Container>
        </section>

        <HomeImages />

        <section >
          <Container className='py-5'>
            <Row className='text-center'>
              <Col xs={12} md={12}>
                <p> With over 35 years of experience in the industry, we specialize in creating beautiful, functional kitchens that are tailored to your lifestyle. Our expert team is passionate about transforming your vision into reality, whether you're looking for a modern, sleek design or a warm, traditional space.</p>
                <p>At Woodstock Kitchens, we understand that the kitchen is the heart of your home, a place where memories are made. That's why we take pride in delivering personalized service from start to finish, ensuring every detail is perfect. Our commitment to quality and customer satisfaction is unmatched, making us the trusted choice for kitchen design and renovation. Explore our portfolio and discover how we can transform your kitchen into a space that you'll love for years to come.</p>
                
                <Button href='/contact'> Contact Us </Button> 
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
}

export default Content