import { Row, Col, Container } from 'react-bootstrap';
import AppContext from '../../AppContext';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Content() {

    const settings = {
        arrows: false,
        dots: true,
        fade: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        adaptiveHeight: true
    };

    return (
        <>
        <section className='testimonial'>
            <Container className='py-5'>
                <Row className='justify-content-md-center m-auto'>
                    <Col xs={12}>
                        <h2>What Customeres Are Saying</h2>
                        <Slider {...settings}>
                            <div>
                                <p>{ AppContext.by_line }</p>
                            </div>
                            <div>
                                <p>Woodstock is our go-to builder and we wouldn’t hesitate to recommend them.</p>
                            </div>
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}

export default Content