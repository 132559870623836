// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
import { Row, Col, Nav, Navbar, Container } from 'react-bootstrap';
import AppContext from '../AppContext';
import { useLocation } from 'react-router-dom';

// import Logo from '../Assets/Images/kitchen.svg';

// import ColorSelector from './ColorSelector';
// import Logo from './Logo';

function NavBar() {

  // const location = useLocation();

  return(
    <>
    {/* <div className='no_mob'>
      <ColorSelector />
    </div> */}
    
    
      <Container className='no_mob'>
        <Row className='' >
          <Col >
            <Navbar.Brand  href="/">Woodstock Kitchens</Navbar.Brand>
          </Col>
          <Col className='text-end'>
            <Navbar.Brand className='no_mob' style={{color: 'var(--color-4)'}}>{AppContext.phone_number}</Navbar.Brand>
          </Col>

        </Row>
      </Container>
      
      
        <Navbar expand="md" className=" p-1 nav nav-underline justify-content-between " id="navbar">
          <Navbar.Toggle aria-controls="basic-navbar-nav mx-1 my-1"></Navbar.Toggle>
          <Navbar.Brand className="mob_only" href="/">Woodstock Kitchens <span className='mob_only gold' style={{ textAlign: 'right'}}>{AppContext.phone_number}</span></Navbar.Brand>

          <Navbar.Collapse className="justify-content-center">
            <Nav className='mb-auto' >
            {/* <Nav activeKey={location.pathname} className='mb-auto' > */}
                <Nav.Link className='nav-item nav-link ' href="/"  >Home</Nav.Link>
                <Nav.Link className='nav-item nav-link ' href="/kitchens" >Kitchens</Nav.Link>
                <Nav.Link className='nav-item nav-link ' href="/bathrooms" >Bathrooms</Nav.Link>
                <Nav.Link className='nav-item nav-link ' href="/structural-work" >Structural Work</Nav.Link>
                <Nav.Link className='nav-item nav-link ' href="/projects" >Our Work</Nav.Link>
                <Nav.Link className='nav-item nav-link no_mob ' href="/testimonials" >Testimonials</Nav.Link>
                <Nav.Link href="/contact" >Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        
        </Navbar>
    </>
  );
}


export default NavBar;