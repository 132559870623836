import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { Row,Container, Col } from 'react-bootstrap';
import { MasonryPhotoAlbum } from "react-photo-album";
import "react-photo-album/masonry.css";

const API_URL = process.env.REACT_APP_API_URL;

const Main = () => {
  const [error, setError] = useState();
  // const [isLoading, setIsLoading] = useState(false);
  // const [projectId, setProjectId] = useState();
  const [files, setFiles] = useState([]);
  const { id } = useParams();

  const [photos, setPhotos] = useState([]);

  var photos_array = []

  useEffect(() => {

    const startFetching = async () => {
      let BASEURL = API_URL + 'count-files/' + id;

      try {

        const response = await fetch(`${BASEURL}`)
        const json = await response.json();

        json[0].photos.forEach((el) => {
          photos_array.push(el);
        });

        // console.log(photos_array);
        setPhotos(photos_array);
        
      } catch (e) {
        // setError(e);
        console.log(e);
      } 

    };
    startFetching();


  }, [id]);


  return (
    <>
    
    <section>
      <Container style={{height: '100vh'}}>
        <Row className="py-2">
          <Col>
            <p>A selection of images from this project.</p>   
          </Col>
        </Row>
        
        <Row className="py-2">
          <Col>
            <MasonryPhotoAlbum photos={photos} />         
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}

export default Main;