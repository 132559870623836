import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AppContext from '../AppContext';
import ContactButton from './ContactButton';



function Footer() {

  return (
        <footer className='p-3'>
            <Container>
                <Row >
                    
                    <Col xs={12} md={6} className="">
                        <h3>Woodstock Kitchens</h3>
                        <p>Design | Install | Happiness</p>
                    </Col>
                    
                    <Col xs={12} md={6} className="text-end justify-content-end">
                        <h3>Get In Touch</h3>
                        <p>{AppContext.phone_number}</p>
                        <ContactButton />                     
                        
                    </Col>
                
                </Row>
                <Row>
                    <Col  className="footer_text">
                        &copy; Woodstock Kitchens {(new Date().getFullYear())}
                        
                    </Col>
                </Row>
            </Container>
        </footer>

  );
}

export default Footer;