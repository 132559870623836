
const AppContext = {
    "phone_number":"07961 123054",
    "by_line": "When David and his team were carrying out the work they were incredibly friendly, hard working, polite and accommodating to the practical needs myself and my family had while the work was being carried out."
};

export default AppContext;


  // // const shuffledArray = project_photos.sort((a, b) => 0.5 - Math.random());
