import { createBrowserRouter,Route,createRoutesFromElements } from "react-router-dom";
import { _Routes } from "./Routes";

export const _Router = createBrowserRouter(
  
  createRoutesFromElements(
    _Routes.map((_Route) => (
      
      <Route path={_Route.path} element={_Route.element} />
    ))
  )
);
