import { Image, Row, Col, Container } from 'react-bootstrap';
import "animate.css/animate.css"
import ScrollAnimation from 'react-animate-on-scroll';

function Content() {
    return (
        <>
        <section className='no_mob' >
          <Container className='py-5'>
            <Row>
              <Col >
                <h2 className='page_title py-3'>Our Latest Projects</h2>
              </Col>
            </Row>
            <Row>       
              <Col>
              <a href="/project/3"><Image src='images/projects/Project_3/project.jpg' fluid className='square_image' /></a>
              </Col>
              <Col>
              <a href="/project/6"><Image src='images/projects/Project_6/project.jpg' fluid className='square_image' /></a>
              </Col>
              <Col>
              <a href="/project/2"><Image src='images/projects/Project_2/project.jpg' fluid className='square_image' /></a>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
}

export default Content