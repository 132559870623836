import Home from '../../Pages/Home/Home';
import Kitchens from '../../Pages/Kitchens';
import Bathrooms from '../../Pages/Bathrooms';
import Construction from '../../Pages/Construction';
import Contact from '../../Pages/Contact';
import Testimonials from '../../Pages/Testimonials/Testimonials';
import Projects from '../../Pages/Projects/Projects';
import Project from '../../Pages/Projects/Project';
import NoMatch from '../../Components/NoMatch';

export const _Routes = [
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/kitchens",
        element:<Kitchens />
    },
    {
        path: "/bathrooms",
        element:<Bathrooms />
    },
    {
        path: "/testimonials",
        element:<Testimonials />
    },
    {
        path: "/contact", 
        element:<Contact />
    },
    {
        path: "/projects", 
        element:<Projects />
    },
    {
        path: "/structural-work", 
        element:<Construction />
    },
    {
        path: "/project/:id", 
        element:<Project />,
        exclude_sitemap: true
    },
    {
        path: "*", 
        element:<NoMatch />
    }

];


export const marky = [
    {
        path: "/",
        element: "<Home />",
    },
    {
        path: "/kitchens",
        element:"<Kitchens />"
    },
    {
        path: "/bathrooms",
        element: "<Bathrooms />"
    },
    {
        path: "/testimonials",
        element: "<Testimonials />"
    },
    {
        path: "/contact", 
        element: "<Contact />"
    },
    {
        path: "/projects", 

    },
    {
        path: "/structural-work", 
    },
    {
        path: "/project/:id", 
    },
    {
        path: "*", 
    }

];
