import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap/dist/react-bootstrap.js";
import './App.css';
import "animate.css/animate.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { RouterProvider } from "react-router-dom";

import { _Router } from "./Constants/Router/Router"
import NavBar from './Components/NavBar';
import Footer from './Components/Footer';

export default function App() {

  return (
     <>
      <NavBar />
      <RouterProvider router={_Router} />
      <Footer />
     </>
  );
}; 
