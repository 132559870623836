import { Component, useRef } from "react"
import { Button, Form } from "react-bootstrap"
import emailjs from "@emailjs/browser";

function ContactForm() {
  
    const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_i7s27x9', 'template_uacz3i6', form.current, {
          publicKey: 'qWR8FoThTG_AT819x',
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };

    return(
        
        <Form ref={form} onSubmit={sendEmail}>
            <Form.Group className="mb-3" controlId="nameRef" >
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="name" aria-describedby="NameHint" />
                <span id="NameHint" hidden>Enter your name.</span>
            </Form.Group>

            <Form.Group className="mb-3" controlId="emailRef">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email"  name="email" />
                <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="phoneRef">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="text" name="phone" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="messageRef">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} name="message" />
            </Form.Group>
            
            <input type="submit" value="Send" className="btn btn-primary w-100 py-2" />
        </Form>
    )
    
}

export default ContactForm