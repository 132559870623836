import { Component, useRef } from "react"
import { Button, Form } from "react-bootstrap"
import emailjs from "@emailjs/browser";

function ContactButton() {
  return (
        <>
          <Button href='/contact'> Contact Us </Button>
        </>
    )
}

export default ContactButton