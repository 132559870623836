//import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';



function NoMatch() {

 useEffect(() => {
     document.title = "404";
   }, []);

  return (
    <div className="App">
      <header className="App-header">


        <p>
          404
        </p>

                <Link to='/'>Go Home </Link>

      </header>
    </div>
  );
}

export default NoMatch;