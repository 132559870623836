import { useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import ContactForm from '../Components/ContactForm'
import page_header from '../Assets/Images/contact_header.jpg';

const Main = () => {


//   useEffect(() => emailjs.init("qWR8FoThTG_AT819x"), []);

// const emailRef = useRef<HTMLInputElement>();
// const nameRef = useRef<HTMLInputElement>();
// const phoneRef = useRef<HTMLInputElement>();
// const messageRef = useRef<HTMLTextAreaElement>();
// const [loading, setLoading] = useState(false);

//   const handsrc/Components/ContactForm.jsleSubmit = async (e) => {
//     e.preventDefault();
//     const serviceId = "service_i7s27x9";
//     const templateId = "template_uacz3i6";
//     try {
//       setLoading(true);
//       await emailjs.send(serviceId, templateId, {
//        name: nameRef.current.value,
//         email: emailRef.current.value,
//         phone: phoneRef.current.value,
//         message: messageRef.current.value
//       });
//       alert("email successfully sent check inbox");
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setLoading(false);
//     }
//   };

  
 useEffect(() => {
     document.title = "Contact Woodstock Kitchens";
   }, []);

  return (
    <>
    <section className='page_header start' style={{backgroundImage: `url(${ page_header })`}}>
        <h1 className='no_mob gold'>Contact Us</h1>
    </section>
    <section>
      <Container className="py-md-3 ">
        <Row>
          <Col xs={12} md={6} >
            <p>Request your free kitchen design visit today.</p>
            <p>Why have a kitchen design visit? Whether you have a clear vision for your dream kitchen or are feeling overwhelmed by all the options, our expert designers are here to help. If you're seeking the confidence to try something bold or feeling unsure about how to tackle a tricky layout, we’ll guide you through the process. Our team makes designing your new kitchen a relaxed, enjoyable experience, so you can feel confident in creating a space that perfectly suits your style and needs.</p>
          </Col>
          
          <Col md={6}>
            <ContactForm />
          </Col>
        </Row>
        </Container>
    </section>
    </>
    );
  }
  
  export default Main;