import { Row, Col, Container, Image } from 'react-bootstrap';
import "animate.css/animate.css"
import ScrollAnimation from 'react-animate-on-scroll';

import home_image_1 from '../../Assets/Images/home_image_1.jpg';
import home_image_2 from '../../Assets/Images/home_image_2.jpg';
import home_image_3 from '../../Assets/Images/home_image_3.jpg';

function Content() {
    return (
      <>
        <section className='py-5'>
          <Container>
            <Row>
              <Col md={6} xs={12} >
                <ScrollAnimation 
                  className="animate__animated"
                  animateIn="animate__fadeInLeft"
                  duration={1}
                >
                  <h2 className='page_title merriweather-regular'>Quality Craftmanship</h2>
                  <p>Our commitment to quality and customer satisfaction has been the cornerstone of our success for three decades. We pride ourselves on using only the finest materials and the latest design trends to create kitchens that are not only aesthetically pleasing but also durable and practical. From the initial consultation to the final installation, we work closely with you every step of the way to ensure your complete satisfaction. Discover the Woodstock Kitchens difference today, and let us help you design the kitchen of your dreams.</p>
                </ScrollAnimation>
              </Col>
          
              <Col md={6} className='no_mob'>
                <Row>
                  <ScrollAnimation 
                    className="animate__animated col-md-4"
                    animateIn="animate__fadeInRight"
                    duration={1}
                  >
                    <Image src={home_image_1} fluid />  
                  </ScrollAnimation>

                  <ScrollAnimation 
                    className="animate__animated col-md-4"
                    animateIn="animate__fadeInRight"
                    duration={1}
                    delay={500}
                  >
                    <Image src={home_image_2} fluid />  
                  </ScrollAnimation>

                  <ScrollAnimation 
                    className="animate__animated col-md-4"
                    animateIn="animate__fadeInRight"
                    duration={1}
                    delay={1000}
                  >
                    <Image src={ home_image_3 } fluid />  
                  </ScrollAnimation>              

                </Row>
          </Col>
          
              
            
            </Row>
          </Container>
        </section>
      </>
    );
}

export default Content