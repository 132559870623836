import { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import backgroundImage from '../../Assets/Images/testimonials.jpg';

const Main = () => {

  
    useEffect(() => {
        document.title = "Woodstock Kitchens";
    }, []);

    const settings = {
      arrows: false,
      dots: true,
      fade: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      waitForAnimate: false,
      autoplay: true,
      autoplaySpeed: 3000,
      cssEase: "linear",
      adaptiveHeight: true
    };

    return (
    <>
      <section  className='py-0' style={{height: '100vh', backgroundSize: 'cover', backgroundImage: `url(${ backgroundImage })`}} >
        <Container >
          <Row className='testimonial-slider'>
            <Col>
              <Slider {...settings}>
                <div className='dark_blur testimonial-caption'>
                  <p>David and his team were personally recommended to me by a friend who has used them as their trusted builder for many years. </p>
                  <p className='no_mob'>David was immediately very personable when he came to look at the job and was able to give me very informative advice about the pros and cons  and the impact on price of the ideas I had.</p>
                  <p className='no_mob'>I also found his guidance as to what specifications would be more attractive and practical without imposing his own views. Without his advice I the room would not look as good as it does now.</p>
                  <p className='no_mob'>I would be more than happy to be contacted if anyone would like any further details. </p>
                  <p><span>Andy</span></p>
                </div>

                <div className='dark_blur testimonial-caption'>
                  <p>We have nothing but praise for Woodstock Construction. David and his team are a pleasure to have in your home - polite, on-time, tidy - and we have always been delighted with the finished product. </p>
                  <p>They have transformed our kitchen from a dark hole into a light, bright and large kitchen diner by knocking through two rooms and making it a space we love to entertain. </p>
                  <p>David is not only a great builder but he has great vision and is brilliant with design ideas. His idea of a picture window in our kitchen was inspired. </p>
                  <p>Our bathroom has also been transformed into a place we love to spend time. Previously two separate rooms - one with shower and one with a bath - the bathroom is now luxurious and we actually love taking baths now.</p>
                  <p>Woodstock is our go-to builder and we wouldn’t hesitate to recommend them.</p>
                </div>
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    </>
    );
}

export default Main;