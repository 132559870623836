import { useEffect} from 'react';

import SlideShow from './SlideShow'
import IntroContent from './IntroContent'
import MidContent from './MidContent'
import Testimonials from './Testimonials'
import LatestProjects from './LastestProjects'

const Main = () => {

  
 useEffect(() => {
     document.title = "Woodstock Kitchens | Kitchen and Bathroom Fitters in Sussex";
     document.description = "Woodstock Kitchens offers bespoke kitchen designs tailored to your style and needs. With expert craftsmanship, high-quality materials, and personalized service, we transform your space into the heart of your home. Serving Sussex, we specialize in modern, traditional, and custom kitchen solutions."
   }, []);

  return (
    <>
      {/* <SlideShow /> */}

      <IntroContent />
      <MidContent />
      <Testimonials />
      <LatestProjects />
    </>
  );
}

export default Main;