import { useState, useEffect } from "react";

import { Row, Col, Card, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import page_header from '../../Assets/Images/contact_header.jpg';

const API_URL = process.env.REACT_APP_API_URL;
let BASEURL = API_URL + 'count';

let gallery_count = 0;


const Main = () => {
  
  const [files, setFiles] = useState([]);
  
 useEffect(() => {
     document.title = "Woodstock Kitchens - Latest Photos";
    //  let BASEURL = API_URL + 'count';

    const startFetching = async () => {
      try {

        const response = await fetch(`${BASEURL}`)
        const json = await response.json();

        const gallery_count = json[0].count
        
        console.log(gallery_count);

        const card_photos = Array(gallery_count).fill().map((item, i) => (
          <Col xs={6} md={4}>
            <Card >
              <Card.Img variant="top" src={`./images/projects/Project_${i + 1}/project.jpg`} fluid className="project_photo"/>
              <Card.Body>
                {/* <Card.Title>Project Title</Card.Title> */}
                <Card.Text>
                  
                </Card.Text>
                <Button variant="primary" href={`project/${i+1}`} >View Project</Button>
              </Card.Body>
            </Card>
          </Col> 
        ));
        let shuffledArray = card_photos.sort((a, b) => 0.5 - Math.random())
        setFiles(shuffledArray)
        
      } catch (e) {
        console.log(e);
      } 

    };
    startFetching();


   }, []);

  return (
    <>
      <section className='page_header start' style={{backgroundImage: `url(${ page_header })`}}>
          <h1 className='myBlur no_mob'>Our Projects</h1>
      </section>
      <section>
        <Container className="py-3">
          <Row>
            <Col>
              <p>Check out the stunning photos of our latest projects in kitchens and bathrooms! At Woodstock Kitchens, we take pride in transforming ordinary spaces into extraordinary ones. Our recent kitchen renovations showcase modern designs with high-quality finishes, while our bathroom projects highlight luxurious fixtures and smart layouts. Browse our gallery to see how we can bring your vision to life with our expert craftsmanship and attention to detail.</p>
            </Col>
          </Row>
          <Row>
            { files }
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Main;